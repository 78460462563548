import * as amplitude from "@amplitude/analytics-browser";
import { AuthProvider, useAuthInfo } from "@propelauth/react";
import * as Sentry from "@sentry/nextjs";
import type { AppProps } from "next/app";
import { type AppType } from "next/app";
import { ReactNode, createContext, useEffect } from "react";
import { Toaster } from "react-hot-toast";
import "../styles/globals.css";
import { initAmplitude } from "../utils/amplitude";
import { api, setToken } from "../utils/api";
import FPSStatsWidget from "./FPSStatsWidget";

type AmplitudeContextType = {
  amplitude: typeof amplitude;
} | null;

export const AmplitudeContext = createContext<AmplitudeContextType>(null);

const AmplitudeContextProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  useEffect(() => {
    initAmplitude();
  }, []);

  return (
    <AmplitudeContext.Provider value={{ amplitude }}>
      {children}
    </AmplitudeContext.Provider>
  );
};

const App: AppType = ({ Component, pageProps }: AppProps) => {
  return (
    <AuthProvider authUrl={process.env.PROPEL_AUTH_URL ?? ""}>
      {/* 
      // @ts-ignore */}
      <AmplitudeContextProvider>
        <FPSStatsWidget />
        <ApiAuthSetter />
        <Component {...pageProps} />
      </AmplitudeContextProvider>
      <Toaster />
    </AuthProvider>
  );
};

const ApiAuthSetter = () => {
  const authInfo = useAuthInfo();
  setToken(authInfo?.accessToken ?? "");
  Sentry.setUser({ username: authInfo?.user?.email ?? "No user email found" });
  return null;
};

export default api.withTRPC(App);
