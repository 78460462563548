import * as amplitude from "@amplitude/analytics-browser";
import { sessionReplayPlugin } from "@amplitude/plugin-session-replay-browser";

export const trackAmplitudeEvent = (
  eventType: string | amplitude.Types.BaseEvent,
  eventProperties?: Record<string, unknown>
) => {
  amplitude.track(eventType, eventProperties);
};

export const setAmplitudeUserId = (userId: string) => {
  amplitude.setUserId(userId);
};

export const setAmplitudeUserProperties = (
  userProperties: Record<string, string>
) => {
  const identify = new amplitude.Identify();
  Object.entries(userProperties).forEach(([key, value]) => {
    identify.set(key, value);
  });
  amplitude.identify(identify);
};

// Function to start default tracking
export const startAmplitudeTracking = async (isInternalUser: boolean) => {
  let sampleRate = isInternalUser ? 0 : 1;
  // Initialize the session replay plugin
  const sessionReplayTracking = sessionReplayPlugin({
    sampleRate: sampleRate,
  });

  // Add the session replay plugin to Amplitude
  await amplitude.add(sessionReplayTracking).promise;
  const AMPLITUDE_API_KEY =
    process.env.NODE_ENV === "development" || process.env.STAGING === "true"
      ? process.env.NEXT_DEV_AMPLITUDE_API_KEY || ""
      : process.env.NEXT_LIVE_AMPLITUDE_API_KEY || "";
  amplitude.init(AMPLITUDE_API_KEY, {
    defaultTracking: {
      sessions: true,
      pageViews: true,
      formInteractions: true,
    },
  });
};
export const logAmplitudeEvent = (
  eventType: string | amplitude.Types.BaseEvent,
  eventProperties?: Record<string, unknown>
) => {
  amplitude.logEvent(eventType, eventProperties);
};

export const initAmplitude = async () => {
  // Determine the appropriate API key based on the environment
  const AMPLITUDE_API_KEY =
    process.env.NODE_ENV === "development" || process.env.STAGING === "true"
      ? process.env.NEXT_DEV_AMPLITUDE_API_KEY || ""
      : process.env.NEXT_LIVE_AMPLITUDE_API_KEY || "";

  // Initialize Amplitude with the API key and default tracking options
  amplitude.init(AMPLITUDE_API_KEY, {
    defaultTracking: false,
  });
};
