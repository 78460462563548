/**
 * This is the client-side entrypoint for your tRPC API. It is used to create the `api` object which
 * contains the Next.js App-wrapper, as well as your type-safe React Query hooks.
 *
 * We also create a few inference helpers for input and output types.
 */
import {
  createWSClient,
  httpBatchLink,
  httpLink,
  loggerLink,
  splitLink,
  wsLink,
} from "@trpc/client";
import { createTRPCNext } from "@trpc/next";
import { type inferRouterInputs, type inferRouterOutputs } from "@trpc/server";
import getConfig from "next/config";
import superjson from "superjson";

import { type AppRouter } from "../server/api/root";

const getBaseUrl = () => {
  if (typeof window !== "undefined") return ""; // browser should use relative url
  if (process.env.VERCEL_URL) return `https://${process.env.VERCEL_URL}`; // SSR should use vercel url
  return `http://localhost:${process.env.PORT ?? 3000}`; // dev SSR should use localhost
};

const getWsUrl = () => {
  const { publicRuntimeConfig } = getConfig();
  let tennr_url = process.env.TENNR_URL;

  // Default to prod server
  if (!tennr_url) return "wss://app.tennr.com:3000/api/trpc";

  // Construct websocket url from https url
  tennr_url = tennr_url.replace("https", "wss").replace("http", "ws");
  const isSingleServer = publicRuntimeConfig
    ? publicRuntimeConfig.SINGLE_SERVER === "true" ||
      process.env.NODE_ENV === "production"
    : false;
  if (!isSingleServer) {
    tennr_url = tennr_url.replace(":3000", ":3001");
  }
  return `${tennr_url}/api/trpc`;
};

// Save the auth token for use in the headers
let token: string;
export function setToken(newToken: string) {
  token = newToken;
}

function getEndingLink() {
  if (typeof window === "undefined")
    return httpBatchLink({
      url: `${getBaseUrl()}/api/trpc`,
      maxURLLength: 2083, // from https://trpc.io/docs/client/links/httpBatchLink#setting-a-maximum-url-length

      /**
       * Automatically apply the current login token to all requests
       */
      headers() {
        return {
          Authorization: `Bearer ${token}`,
        };
      },
    });
  return splitLink({
    condition(op) {
      return op.path === "interactions.subscribeWorkflowStepResults";
    },
    true: wsLink({
      client: createWSClient({
        url: getWsUrl(),
      }),
    }),
    false: splitLink({
      condition(op) {
        // check for context property `skipBatch`
        return (
          op.path === "variables.fetchByInteractionId" ||
          op.context.skipBatch === true
        );
      },
      // when condition is true, use normal request
      true: httpLink({
        url: `${getBaseUrl()}/api/trpc`,

        /**
         * Automatically apply the current login token to all requests
         */
        headers() {
          return {
            Authorization: `Bearer ${token}`,
          };
        },
      }),
      // when condition is false, use batching
      false: httpBatchLink({
        url: `${getBaseUrl()}/api/trpc`,
        maxURLLength: 2083, // from https://trpc.io/docs/client/links/httpBatchLink#setting-a-maximum-url-length

        /**
         * Automatically apply the current login token to all requests
         */
        headers() {
          return {
            Authorization: `Bearer ${token}`,
          };
        },
      }),
    }),
  });
}

/** A set of type-safe react-query hooks for your tRPC API. */
export const api = createTRPCNext<AppRouter>({
  config() {
    return {
      /**
       * Transformer used for data de-serialization from the server.
       *
       * @see https://trpc.io/docs/data-transformers
       */
      transformer: superjson,

      /**
       * Links used to determine request flow from client to server.
       *
       * @see https://trpc.io/docs/links
       */
      links: [
        loggerLink({
          enabled: (opts) =>
            process.env.NODE_ENV === "development" ||
            (opts.direction === "down" && opts.result instanceof Error),
        }),
        getEndingLink(),
      ],
    };
  },
  /**
   * Whether tRPC should await queries when server rendering pages.
   *
   * @see https://trpc.io/docs/nextjs#ssr-boolean-default-false
   */
  ssr: false,
});

/**
 * Inference helper for inputs.
 *
 * @example type HelloInput = RouterInputs['example']['hello']
 */
export type RouterInputs = inferRouterInputs<AppRouter>;

/**
 * Inference helper for outputs.
 *
 * @example type HelloOutput = RouterOutputs['example']['hello']
 */
export type RouterOutputs = inferRouterOutputs<AppRouter>;
