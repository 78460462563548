import { useEffect, useState } from "react";
import { useFps } from "react-fps";
import { useAuthInfo } from "@propelauth/react";

const FPSStatsWidget = () => {
  const [isClient, setIsClient] = useState(false);
  const [visible, setVisible] = useState(false);
  const isDevEnvironment =
    process.env.NODE_ENV === "development" || process.env.STAGING === "true";
  const authInfo = useAuthInfo();
  const teamId = authInfo.orgHelper?.getOrgIds()[0] ?? "";
  const isDeveloper = authInfo.accessHelper?.isAtLeastRole(
    teamId,
    "Tennr Developer"
  );

  const { fps, avgFps, maxFps, currentFps } = useFps(20);

  useEffect(() => {
    setIsClient(true);

    const handleKeyDown = (event: KeyboardEvent) => {
      // Toggle visibility on ctrl + shift + F
      if (event.ctrlKey && event.shiftKey && event.key === "F") {
        setVisible((prevVisible) => !prevVisible);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  // Show the FPS stats on toggle if the user is a developer or in a dev environment
  return isClient && visible && (isDevEnvironment || isDeveloper) ? (
    <>
      <div className="flex flex-col absolute bottom-0 right-0 z-50 bg-slate-700 w-36 h-24 overflow-x-hidden rounded-tl-md">
        {/* Top Bar */}
        <div className="flex flex-row w-full bg-slate-950 text-sm p-1 text-green-500">
          <div>FPS: {currentFps}</div>
          <div className="ml-auto">Avg: {avgFps}</div>
        </div>
        <div className="flex flex-row h-full items-end">
          {fps.map((frame, index) => {
            return (
              <div
                key={index}
                className={`bg-green-500 justify-self-end`}
                style={{ height: `${(100 * frame) / 160}%`, width: `5%` }}
              ></div>
            );
          })}
        </div>
      </div>
    </>
  ) : null;
};

export default FPSStatsWidget;
